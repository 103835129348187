import axios from "@/utils/axios";

export const list = 'shop/list'
export const consume = 'shop/consume'
export const record = 'shop/record'

export const ShopAdd = (data) => {
    return axios.post(`shop/add`, data)
}
export const ShopEdit = (data) => {
    return axios.post(`shop/edit`, data)
}