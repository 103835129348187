<template>
    <div class="">
        <ATable :url="tableUrl" :columns="columns" :searchFormItems="searchFormItems" ref="table">
            <el-button type="primary" @click="Editor()">新增</el-button>
        </ATable>
    </div>
</template>


<script>
import ATable from '@/components/table/index'
import { provide, ref } from 'vue'

import { consume } from '@/api/shopping'
export default {
    components: {ATable},
    setup () {
        const tableUrl = ref(consume)
        const columns = ref([
            {prop: 'shopName', label: '商品名称'},
            {prop: 'type', label: '商品类型', render (text) {
                // 1 食物，2 装扮，3 道具
                let label = {1: '食物', 2: '道具', 3: '装扮'}
                return label[text]
            }},
            {prop: 'userName', label: '用户名称'},
            {prop: 'shopImg', label: '图片', type: 'image'},

        ])
        const searchFormItems = ref([
            {label: '商品名称', prop: 'shopName', type: 'input'},
            {label: '商品类型', prop: 'type', type: 'select', options: [{label: '食物', value: 1}, {label: '道具', value: 2}, {label: '装扮', value: 3}]},
        ])
        let editorForm = ref({})
        let table = ref(null)
        provide('editorForm', editorForm)

        const methods = {
        }

        return {
            table,
            tableUrl,
            columns,
            searchFormItems,
            ...methods
        }
    }
}
</script>

<style lang="scss" scoped>

</style>